<template>
<div class="main">
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">

      <template v-slot:page-name>产品配料</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>
    </TopMenu>

    <div class="content bg-25 p-2 flex-1">
      <div class="flex-list mb-2">
        <div style="width:50%;">
          <label class="font-12">产品</label>
          <inputSelect v-model="targetProduct" label="name" :options="products" placeholder="请选择产品" />
        </div>
        <div style="width:50%;">
          <label class="font-12">组份价格</label>
          <div class="p-1">
            <label>
              <input type="radio" name="radios" value="1" v-model="priceParam">
              最新进货价
            </label>
            <label>
              <input type="radio" name="radios" value="2" v-model="priceParam">
              库存平均价
            </label>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="flex-list-header"></div>
        <div class="flex-list hr w-100">
          <div style="width:30%;padding-left:24px;">物料</div>
          <div style="width:25%;">用量%</div>
          <div style="width:15%;">数量kg</div>
          <div style="width:15%;">单价</div>
          <div style="width:15%;">金额</div>
        </div>
      </div>

      <div class="d-flex" v-for="(item, index) in compose" :key="index">
          <div class="flex-list-header">
            <div class="bg-38">{{ index + 1 }}</div>
            <div class="delete bg-red" @click="deleteRow(index)">-</div>
          </div>
          <div class="flex-list hr w-100">
            <div style="width:30%;padding-left:24px;">
                <inputSelect v-model="compose[index].id" label="name" :options="products" placeholder="请选择产品" inputClass="form-control p-0 bg-none" @update:modelValue="updateProductId(index)" />
            </div>
            <div style="width:25%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="compose[index].quantity">
            </div>
            <div style="width:15%;">
              {{ $math.Mul(1, $math.Div(compose[index].quantity, 100)) }}
            </div>
            <div style="width:15%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0.00" autoComplete="off" v-model="compose[index].price">
            </div>
            <div style="width:15%;">
              {{ rowTotal(index) }}
            </div>
          </div>
      </div>
      <button class="btn btn-create p-0" @click="create()">+</button>

      <h2 class="text-slim">总金额<span class="pl-1">￥{{ totalPriceComputed() }}</span></h2>

      <div class="mb-2 mt-1"><textarea style="resize: none;" class="form-control" placeholder="备注" v-model="remarks"></textarea></div>

    </div>

  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import inputSelect from '@/components/inputSelect.vue'
//import { DatePicker } from 'v-calendar';
//import formatDate from '@/utils/formatDate.js';

export default {
  name: 'ProductionComposeEdit',

  data() {
    return {
      loading: false,
      id: null,
      targetProduct: null,
      compose: [
        {
          id: null,
          quantity: null,
          price: null,
          total: 0,
        }
      ],
      remarks: null,
      products: [],
      priceParam: 1,
      customerSelect: {
        show: false,
        top: 0,
        left: 0
      },
      allowAttack: false,
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    this.getProductList()

    if (this.$route.params.id) {
      this.updateOrder()
      console.log('修改')
    }
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
    inputSelect,
    //DatePicker,
  },

  methods: {
    onNumberInput(index) {
      this.order.order_list[index].quantity = this.$math.Mul(this.order.order_list[index].number, this.order.order_list[index].specification)
    },
    onQuantityInput(index) {
      this.order.order_list[index].number = this.$math.Div(this.order.order_list[index].quantity, this.order.order_list[index].specification)
    },

    //获取产品列表
    getProductList(customer) {
      this.$axios.get('/api/product/product-Last-price', {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
        params: {
          customer: customer
        }
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.products = res.data
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    //更新订单
    updateOrder() {
      this.$axios.get('/api/production/get-compose'+this.$route.params.id, {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.order = res.data
            this.number = res.data.number
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    dayClicked() {
      console.log(this.order.deliver_at)

      // 对比月份是否有改变
      let deliver_at = new Date(this.order.deliver_at);
      let nowMonth = new Date();
      if (deliver_at.getMonth() == nowMonth.getMonth()) {
        // 如果是修改订单
        if (this.number) {
          this.order.number = this.number
        }
      } else {
        let _this = this
        this.getLastNumber(this.order.deliver_at).then(res => {
          _this.order.number = res.data
        })
      }
    },

    create() {
      this.compose.push({
        id: null,
        quantity: null,
        price: null,
        total: 0,
      })
    },

    deleteRow(index) {
      if (this.compose.length > 1) {
        this.compose.splice(index, 1)
      }
    },

    destroy() {
      this.$router.push({name: 'OrderList'})
    },

    updateProductId(i) {
      for (let t = 0; t < this.products.length; t++) {
        if (this.products[t].id == this.compose[i].id) {
          // 自动填充默认单价
          if (this.priceParam == 1) {
            this.compose[i].price = this.products[t].inbound_price
          } else {
            let price = 0;
            price = this.$math.Div(this.products[t].total_price, this.products[t].quantity)
            this.compose[i].price = price
          }
          
        }
      }
    },

    getCompose(targetProduct) {
      this.$axios.get('/api/production/get-compose', {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
        params: {
          target_product: targetProduct,
        }
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data.data) {
            if (res.data.data.remarks) {
              this.remarks = res.data.data.remarks
            }

            if (res.data.data.compose) {
              this.compose = JSON.parse(res.data.data.compose)

              for (var i = 0; i < this.compose.length; i++) {
                for (var t = 0; t < this.products.length; t++) {
                  if (this.products[t].id == this.compose[i].id) {
                    // 自动填充默认单价
                    //if (!this.compose[i].price) {
                      this.compose[i].price = this.products[t].inbound_price
                    //}
                    
                  }
                }
              }
            }
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    save() {
      let _this = this
      this.allowAttack = true
      return new Promise((resolve, reject) => {
        try {
          if (this.targetProduct==null) throw '请选择目标产品'

          // 正则验证是否填写数字
          let reg = /^[0-9]+.?[0-9]*/;
          this.compose.forEach(function(item) {
            if (!reg.test(item.id) || !reg.test(item.quantity)) throw '请填写必要的信息'

            delete item.price
            delete item.total
          })
          
          _this.$axios.post('/api/production/compose-save',
            {
              target_product: _this.targetProduct,
              compose: JSON.stringify(_this.compose),
              remarks: _this.remarks
            },
            {
              headers: {
                token: JSON.parse(sessionStorage.Authorization).token
              }
            }
          )
          .then(res => {
            _this.allowAttack = false

            if (res.status == 200) {
              resolve(res);
              console.log(res.data)
              if (res.data.success) {
                this.id = res.data.data.id
                this.$toast.success(res.data.message)
              } else {
                this.$toast.error(res.data.message)
              }
              
            }
          })
          .catch(error => {
            reject(error)
            _this.allowAttack = false
            console.error('error:', error)
          })
         
        } catch(error) {
          console.error(error)
          _this.allowAttack = false
          this.$toast.error(error)
        }
      })
    },

    saveAndPrint() {
      this.save().then(res => {
        if (res.data.success) {
          this.$router.push({name: 'OrderPrint', params: {id: res.data.data.id}})
        }
      })
    },
  },

  watch: {
    targetProduct: function() {
      this.compose = [
        {
          id: null,
          quantity: null,
          price: 0,
          total: 0,
        }
      ];
      this.getCompose(this.targetProduct)
    },

    priceParam: function() {
      let _this = this
      this.compose.forEach(function(item) {
        _this.products.forEach(function(t) {
          if (item.id == t.id) {
            if (_this.priceParam == 1) {
              item.price = t.inbound_price
            } else {
              let price = 0;
              price = _this.$math.Div(t.total_price, t.quantity)
              item.price = price
            }
          }
        })
      })
    }
  },

  computed: {

    rowTotal() {
      return (index) => {
        this.compose[index].total = this.$math.formatMoney(this.$math.Mul(this.$math.Mul(1, this.$math.Div(this.compose[index].quantity, 100)), this.compose[index].price), 2)
        return this.compose[index].total
      }
    },

    totalPriceComputed() {
      return() => {
        let _this = this;
        let totalPrice = 0;
        this.compose.forEach(function(item) {
          totalPrice = _this.$math.Add(totalPrice, item.total)
        })
        return this.$math.formatMoney(totalPrice, 2)
      }
    }

  }
}

</script>

<style lang="scss" scoped>

.flex-list-header .delete {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flex-list-header:hover .delete {
  display: block;
}
.btn-create {
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  padding: 0;
  flex-shrink: 0;
  background-color: #004bff;
}

::v-deep .vc-container.vc-is-dark {
  color: #fff;
  background-color: #383838;
  border: none;
}
 
</style>
